import {
  Component,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
} from '@angular/core';

//import SwiperCore, { Lazy, Pagination, Navigation, Keyboard } from 'swiper';

// install Swiper modules
//SwiperCore.use([Lazy, Pagination, Navigation, Keyboard]);

import { CommonModule } from '@angular/common';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { FileCarousel } from '@shared/models/files';
register();

@Component({
  selector: 'ic-slide-swpiper',
  templateUrl: './slide-swpiper.component.html',
  styleUrls: ['./slide-swpiper.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SlideSwpiperComponent {
  @Input() files: FileCarousel[] = [];
  @Input() fullscreen = false;
  @Output() eventClickSlide: EventEmitter<any> = new EventEmitter<any>();
  @Input() modal = true;

  onSlideChange() {
    //console.log('slide change');
  }

  eventClickImgEmit() {
    this.eventClickSlide.emit();
  }
}
