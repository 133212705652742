import { Component, output } from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';

@Component({
  selector: 'ic-mat-calendar',
  template: `<mat-card>
    <mat-calendar #calendar (selectedChange)="selectedChange($event)" [selected]="dateRange" />
  </mat-card>`,

  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
  imports: [MatCardModule, MatDatepickerModule],
})
export class CalendarComponent {
  startDate: Date;
  endDate: Date;

  range = output<DateRange<Date>>();

  constructor() {
    this.endDate = new Date();

    this.startDate = new Date(this.endDate);
    this.startDate.setMonth(this.startDate.getMonth());

    this.dateRange = new DateRange<Date>(this.startDate, this.endDate);
  }

  dateRange: DateRange<Date>;

  selectedChange(m: any) {
    if (this.startDate && this.endDate) {
      //both has value, reset range picker interval
      this.dateRange = new DateRange<Date>(m, null);
    } else {
      let start = this.dateRange.start,
        end = m;

      if (end < start) this.dateRange = new DateRange<Date>(end, start);
      else this.dateRange = new DateRange<Date>(start, end);
    }

    this.startDate = this.dateRange.start;
    this.endDate = this.dateRange.end;

    this.range.emit(this.dateRange);
  }
}
