<ng-container *ngTemplateOutlet="slide_swiper"></ng-container>

<ng-template #slide_swiper>
  @if (files.length > 0) {
    <swiper-container
      #swiper
      style="--swiper-navigation-color: #70be01; --swiper-pagination-color: #70be01"
      [lazy]="true"
      [loop]="true"
      [pagination]="{ clickable: true }"
      [navigation]="true"
      [keyboard]="{ enabled: true }"
      (slideChange)="onSlideChange()"
      class="mySwiper">
      @for (file of files; track file) {
        @defer (on viewport; on timer(5000)) {
          <swiper-slide>
            @if (file.type === 'image') {
              <img
                width="450"
                height="450"
                [src]="file.url"
                [ngClass]="
                  fullscreen ? 'h-[18rem] sm:h-[26rem] md:h-[35rem] lg:h-[40rem] xl:h-[50rem]' : 'h-72 sm:h-72 lg:h-80'
                "
                class="swiper-lazy block flex-shrink-0 cursor-pointer object-contain"
                (click)="eventClickImgEmit()"
                priority />
            }
            @if (file.type === 'video') {
              <video
                controls
                [src]="file.url"
                [ngClass]="
                  fullscreen ? 'h-[18rem] sm:h-[26rem] md:h-[35rem] lg:h-[40rem] xl:h-[50rem]' : 'h-72 sm:h-72 lg:h-80'
                "></video>
            }
            <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </swiper-slide>
        } @placeholder {
          <div
            role="status"
            class="animate-pulse space-y-8 md:flex md:items-center md:space-x-8 md:space-y-0 rtl:space-x-reverse">
            <div class="flex h-48 w-full items-center justify-center rounded-sm bg-gray-300 dark:bg-gray-700 sm:w-96">
              <svg
                class="h-10 w-10 text-gray-200 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18">
                <path
                  d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
              </svg>
            </div>

            <span class="sr-only">Loading...</span>
          </div>
        } @loading {
          <span class="font-normal text-ica-primary"> Cargando imagenes</span>
        } @error {
          <span class="font-normal text-ica-primary"> No se encontraron imagenes</span>
        }
      }
    </swiper-container>
  }
</ng-template>
