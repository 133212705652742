import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ic-pop-up',
  standalone: true,
  imports: [MatIconModule],
  template: `
    @if (isOpen) {
      <div class="modal-overlay_custom">
        <div class="modal-content_custom" (click)="$event.stopPropagation()">
          <button class="button_close" (click)="close()">
            <mat-icon class="icon-scaled">close</mat-icon>
          </button>
          <ng-content></ng-content>
        </div>
      </div>
    }
  `,
  styleUrl: './modal.component.scss',
})
export class PopUpComponent {
  @Input() isOpen: boolean = false;
  @Output() closeModal = new EventEmitter<void>();

  close() {
    this.closeModal.emit();
  }
}
